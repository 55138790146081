import { localesText } from "Utils/LocalesText.Util";

import {
  FAQS,
  DEVELOPER_PORTAL,
  OUR_PARTNERS,
  CAREERS,
  MERCHANT_SUPPORT,
  CUSTOMER_SUPPORT,
} from "./URL.Constant";
import { PATHS_NAME } from "./PathName.Constant";
import { BLOG_CATEGORY } from "./Common.Constant";

export const MENUS = [
  {
    id: 1,
    label: localesText("common.shop"),
    url: PATHS_NAME.SHOPS,
    hasSubmenu: false,
  },
  {
    id: 2,
    label: localesText("common.in_store"),
    url: PATHS_NAME.IN_STORE,
    hasSubmenu: false,
  },
  {
    id: 3,
    label: localesText("common.for_business"),
    url: "",
    hasSubmenu: true,
  },
  {
    id: 4,
    label: localesText("common.about"),
    url: PATHS_NAME.ABOUT,
    hasSubmenu: false,
  },
  {
    id: 5,
    label: localesText("common.blog"),
    url: PATHS_NAME.BLOGS + `#${BLOG_CATEGORY.ALL_CATEGORIES.slug}`,
    hasSubmenu: false,
  },
  {
    id: 6,
    label: localesText("common.contact"),
    url: PATHS_NAME.GET_IN_TOUCH,
    hasSubmenu: false,
  },
];

export const BUSINESS_SUBMENUS = {
  INDUSTRY: {
    title: localesText("common.industry"),
    submenu: [
      {
        id: 1,
        label: localesText("common.e_commerce"),
        url: PATHS_NAME.BUY_TO_EARN,
        target: undefined,
      },
      {
        id: 2,
        label: localesText("common.telco"),
        url: `${PATHS_NAME.MERCHANT_API}#telco`,
      },
      {
        id: 3,
        label: localesText("common.bill_service"),
        url: `${PATHS_NAME.MERCHANT_API}#bill_service`,
      },
      {
        id: 4,
        label: localesText("common.marketplace_and_fintech"),
        url: `${PATHS_NAME.MERCHANT_API}#marketplace_and_fintech`,
      },
      {
        id: 5,
        label: localesText("common.smes"),
        url: `${PATHS_NAME.MERCHANT_API}#smes`,
      },
    ],
  },
  LINKS: {
    title: localesText("common.links"),
    submenu: [
      {
        id: 6,
        label: localesText("common.business_faqs"),
        url: FAQS,
        target: "_blank",
      },
      {
        id: 7,
        label: localesText("common.developer_portal"),
        url: DEVELOPER_PORTAL,
        target: "_blank",
      },
      {
        id: 8,
        label: localesText("common.case_studies"),
        url: PATHS_NAME.CASE_STUDIES,
        target: undefined,
      },
    ],
  },
  SOLUTION: {
    title: localesText("common.solution"),
    submenu: [
      {
        id: 9,
        label: localesText("common.payto"),
        url: PATHS_NAME.PAYTO,
        target: undefined,
      },
      {
        id: 10,
        label: localesText("common.xero"),
        url: PATHS_NAME.XERO,
        target: undefined,
      },
      {
        id: 11,
        label: localesText("tyro.tyro"),
        url: PATHS_NAME.TYRO,
        target: undefined,
      },
    ],
  },
};

const INDUSTRY_URL = BUSINESS_SUBMENUS.INDUSTRY.submenu.map((menu) => menu.url);
const LINKS_URL = BUSINESS_SUBMENUS.LINKS.submenu.map((menu) => menu.url);
const SOLUTION_URL = BUSINESS_SUBMENUS.SOLUTION.submenu.map((menu) => menu.url);
export const BUSINESS_URL = [...INDUSTRY_URL, ...LINKS_URL, ...SOLUTION_URL];

export const FOOTER_MENUS = {
  LEFT: [
    {
      id: 1,
      name: localesText("common.merchant_support"),
      url: MERCHANT_SUPPORT,
    },
    {
      id: 2,
      name: localesText("common.customer_support"),
      url: CUSTOMER_SUPPORT,
    },
    {
      id: 3,
      name: localesText("common.developer_portal"),
      url: DEVELOPER_PORTAL,
      target: "_blank",
    },
    {
      id: 4,
      name: localesText("common.terms_and_conditions"),
      url: PATHS_NAME.TERMS,
      target: undefined,
    },
    {
      id: 5,
      name: localesText("common.privacy_policy"),
      url: PATHS_NAME.POLICY,
      target: undefined,
    },
  ],
  RIGHT: [
    {
      id: 5,
      name: localesText("common.our_partners"),
      url: OUR_PARTNERS,
      target: "_blank",
    },
    {
      id: 6,
      name: localesText("common.careers"),
      url: CAREERS,
      target: "_blank",
    },
    {
      id: 7,
      name: localesText("common.contact_us"),
      url: PATHS_NAME.GET_IN_TOUCH,
    },
    {
      id: 8,
      name: localesText("common.blogs"),
      url: PATHS_NAME.BLOGS,
      target: undefined,
    },
  ],
};
